import '@/styles/all.scss';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Panel } from '../../panel/Panel';
import './index.css';

function GmailSideBarApp() {
  const [threadId, setThreadId] = useState('');

  useEffect(() => {
    const handleMessage = (event: {
      origin: string;
      data: { op: string; data: string };
    }) => {
      if (event.origin !== 'https://mail.google.com') {
        return;
      }

      const messageData = event.data;

      switch (messageData.op) {
        case 'SET_THREAD':
          setThreadId(messageData.data);
          break;
        case 'CLEAR_THREAD':
          setThreadId('');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className="App" id="vooma-chrome-extension">
      <style>
        {`.vooma-compose-button {
          opacity: 1 !important;
        }`}
      </style>
      <div>
        <Routes>
          <Route path="*" element={<Panel threadId={threadId} />} />
        </Routes>
      </div>
    </div>
  );
}

export default GmailSideBarApp;
